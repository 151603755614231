import { string } from "prop-types";
//import { companyDetails } from "./../../@types/Booking/companyDetails.d";
import {
  PrivacyTerms,
  SsnByItalianCustomerDetails,
} from "./../../@types/DTO/ApiResponses";
import { SelectedVehicleDetails } from "./../../@types/Booking/SelectedVehicleDetails.d";
import {
  OptionInVehicle,
  Vehicle,
  FilterListResponse,
} from "./../../@types/DTO/Vehicles.d";
import { CreateNewBooking, UserLoginResponse } from "../../@types/DTO/Booking";
import {
  CustomerDetailsResponse,
  CustomerDetailsResponse_2,
  DocumentUploadResponse,
  InsuranceDetails,
  ItalianCustomerDetailsUsingSSNResponse,
} from "../../@types/DTO/ApiResponses";
import axios, { AxiosInstance, AxiosResponse } from "axios";

import { BookingDetails } from "./../../@types/Booking/bookingDetails.d";
import { DetailsFormValues } from "../../components/Layouts/WebCheckInForms/WebCheckInForms.props";
import { Affiliate, Location } from "../../@types/DTO/Locations";
import { PrivacyPolicy } from "../../@types/DTO/privacyPolicy";
import { StateResponse } from "../../@types/DTO/LocationRequests";
import { BookingSourceResponse } from "../../@types/DTO/BookingSource";
import {
  AllPaymentMethodsResponse,
  NewPaymentsMethods,
  PaymentLinkResponse,
} from "../../@types/DTO/AllPaymentMethods";
import { ReservationSuccessfullResponse } from "../../@types/DTO/ReservationDetails";
import { AppSettingResponse } from "../../@types/DTO/AppSetting";
import { companyDetails } from "../../@types/Booking/companyDetails";
import * as DateFns from "date-fns";
import { format } from "date-fns";
import { MacroClassGroup } from "../../@types/DTO/MacroClass";
import i18n from "../../config/i18n";
import { BookingStateRedux } from "../../store/onlineBooking/states";
import { NexiChargeTransactionDetails } from "../../@types/Booking/nexiTransactionDetails";
import { StripeTransactionDetails } from "../../@types/Booking/stripeTransactionDetails";
import { ChosenOptions } from "../../components/Layouts/BookingVehicleInfoCard/BookingVehicleInfoCard.props";

//import { AllBookingApi } from "../../@types/API/BookingAvailableApi";

class BookingApi {
  allReservationSources: BookingSourceResponse[] = [];
  MacroClassList: MacroClassGroup[] = [];
  public bookingApiRequestInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL_2,
  });
  constructor() {
    this.getAllCountries();
    this.getStates("ITALIA");
  }

  getAllReservationSources(
    selectedAffiliate: string
  ): Promise<BookingSourceResponse[]> {
    if (!selectedAffiliate) {
      if (this.allReservationSources.length) {
        return Promise.resolve(this.allReservationSources);
      }
    }
    return this.bookingApiRequestInstance
      .get<BookingSourceResponse[]>(
        `/settings/channels?language=${i18n.language}&affiliate=${selectedAffiliate}`
      )
      .then((e) => {
        this.allReservationSources = e.data;
        return e.data;
      });
  }

  getAllMacroGroups(): Promise<MacroClassGroup[]> {
    return this.bookingApiRequestInstance
      .get<MacroClassGroup[]>("/reservations/macroClassList")
      .then((e) => {
        return e.data;
      });
  }

  getBookingDetailsByVoucherCode(
    reservationVoucher: string,
    firstName: string,
    lastName: string,
    companyName: string
  ) {
    return this.bookingApiRequestInstance.post<CreateNewBooking>(
      process.env.REACT_APP_SERVER_URL_2 + "/reservations/by/voucher",
      {
        reservationVoucher: reservationVoucher,
        customerFirstName: firstName,
        customerLastName: lastName,
        companyName: companyName,
        showAdditionalDriverFee: true,
        fetchDocumentTypes: "true",
      }
    );
  }
  getReservationVehicles(bookingDetails: BookingDetails) {
    if (
      !bookingDetails?.pickUpDateAndTime ||
      !bookingDetails?.dropOffDateAndTime == null
    ) {
      return Promise.reject("Dates not found");
    }

    return this.bookingApiRequestInstance.post(
      process.env.REACT_APP_SERVER_URL_2 +
        "/reservations/touroperator/quotations",
      {
        endDate:
          DateFns.format(bookingDetails.dropOffDateAndTime, "yyyy-MM-dd") +
          "T" +
          DateFns.format(bookingDetails.dropOffDateAndTime, "HH:mm:ss"),
        startDate:
          DateFns.format(bookingDetails.pickUpDateAndTime, "yyyy-MM-dd") +
          "T" +
          DateFns.format(bookingDetails.pickUpDateAndTime, "HH:mm:ss"),
        age: 25,
        isYoungDriverAge: bookingDetails.isYoungDriverAge,
        isYoungDriver2Age: bookingDetails.isYoungDriver2Age,
        isSeniorDriverAge: bookingDetails.isSeniorDriverAge,
        noFeeAge: bookingDetails.noFeeAge,
        pickUpLocation: bookingDetails.pickUpLocationDetails.code,
        dropOffLocation: bookingDetails.dropOffLocationDetails.code,
        showPics: bookingDetails.showPics,
        showOptionalImage: bookingDetails.showOptionalImage,
        showVehicleParameter: bookingDetails.showVehicleParameter,
        showVehicleExtraImage: bookingDetails.showVehicleExtraImage,
        showBookingDiscount: bookingDetails.showBookingDiscount,
        agreementCoupon: bookingDetails.agreementCoupon,
        macroGroupName: bookingDetails.macroGroupName,
        bookingChannel:
          bookingDetails.reservationSource != null
            ? bookingDetails.reservationSource.code
            : "WEB001",
      }
    );
  }
  getBookingDetailsByBookingDateAndReservationCode(
    reservationPrefix: string,
    reservationNumber: string,
    reservationDate: Date
  ) {
    return this.bookingApiRequestInstance.post<CreateNewBooking>(
      process.env.REACT_APP_SERVER_URL_2 + "/reservations/by/number",
      {
        reservationDate:
          DateFns.format(reservationDate, "yyyy-MM-dd") +
          "T" +
          DateFns.format(reservationDate, "HH:mm:ss"),
        reservationPrefix,
        reservationNumber: parseInt(reservationNumber),
        showAdditionalDriverFee: true,
        fetchDocumentTypes: "true",
      }
    );
  }
  registerNewUser(e) {
    return this.bookingApiRequestInstance.post<CreateNewBooking>(
      process.env.REACT_APP_SERVER_URL_2 + "/customer/register",
      {
        firstName: e.firstName,
        middleName: e.middleName,
        lastName: e.lastName,
        companyName: e.companyName,
        mobileNumber: e.mobileNumber,
        email: e.email,
        country: e.country,
        state: e.state,
        city: e.city,
        streetName: e.streetName,
        streetNumber: e.streetNumber,
        Zip: e.zip,
        vatNumber: e.vatNumber,
        ssnNumber: e.ssnNumber,
        sex: e.sex,
        telephone: e.telephone,
        birthDate: e.birthDate,
        birthPlace: e.birthPlace,
        birthProvidence: e.birthProvince,
        birthNation: e.birthNation,
        isDriver: e.isDriver,
        licence: e.license,
        issuedBy: e.issuedBy,
        licenceType: e.licenseType,
        documentIssueDate: e.documentIssueDate,
        documentExpiryDate: e.documentExpiryDate,
        documentNumber: e.documentNumber,
        document: e.document,
        customerId: e.customerId,
        customerType: e.customerType,
        password: e.password,
      }
    );
  }
  onlineUserAuthentication(
    //missing in proxy server
    email: string,
    password: string
  ): Promise<AxiosResponse<UserLoginResponse>> {
    // const RequestFormat = AllBookingApi.signIn(email, password);
    return this.bookingApiRequestInstance.post<UserLoginResponse>(
      process.env.REACT_APP_SERVER_URL_2 + "/customer/login",
      {
        email,
        password,
      }
    );
  }

  addDrivers(
    reservationId: number,
    driverDetails: [DetailsFormValues, DetailsFormValues, DetailsFormValues]
  ): Promise<AxiosResponse<CustomerDetailsResponse_2>[]> {
    const driverDetails_filtered = driverDetails
      .map((e) => {
        if (!e) {
          return;
        }
        const temp = e;
        for (const i of Object.keys(temp)) {
          if (!temp[i]) {
            delete temp[i];
          }
        }
        return {
          ...temp,
          zip: parseInt(e.zip || ""),
          isDriver: e.isDriver.toLowerCase() == "yes",
          customerType: e.customerType.toUpperCase(),
          issuedBy: e.issueBy || "",
        };
      })
      .filter((e) => {
        return !!e;
      });
    return axios
      .post(
        process.env.REACT_APP_SERVER_URL_2 +
          "/driver/add/reservation/" +
          reservationId,
        {
          driverDetails: driverDetails_filtered,
        }
      )
      .then((e) => {
        e.data.map((e: CustomerDetailsResponse_2, index) => {
          if (
            (driverDetails_filtered[index]?.identificationDocuments &&
              driverDetails_filtered[index]?.identificationDocuments?.length) ||
            (driverDetails_filtered[index]?.drivingLicenseDocuments &&
              driverDetails_filtered[index]?.drivingLicenseDocuments?.length)
          ) {
            const formData = new FormData();
            driverDetails_filtered[index]?.identificationDocuments.forEach(
              (el) => formData.append("idDoc", el)
            );
            driverDetails_filtered[index]?.drivingLicenseDocuments.forEach(
              (el) => formData.append("driverLicenseDocument", el)
            );

            this.bookingApiRequestInstance
              .post(
                `${process.env.REACT_APP_SERVER_URL_2}/driver/upload-documents-vrent/${e.customerId}`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((response) => {
                console.log("Documents uploaded successfully:", response.data);
              })
              .catch((error) => {
                console.error("Error uploading documents:", error);
                throw error;
              });
          }
        });
        return e.data;
      });
  }

  addDocuments(
    customerId: number,
    documents: Array<File>,
    driverLicensedocument: Array<File>
  ): Promise<AxiosResponse<DocumentUploadResponse>> {
    const formData = new FormData();

    documents.forEach((el) => formData.append("idDoc", el));
    driverLicensedocument.forEach((el) =>
      formData.append("driverLicenseDocument", el)
    );
    try {
      const response = axios.put<DocumentUploadResponse>(
        `${process.env.REACT_APP_SERVER_URL_2}/documents/${customerId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error uploading documents:", error);
      throw error;
    }
  }

  async getCustomerDetails(
    customerId: number
  ): Promise<AxiosResponse<CustomerDetailsResponse_2>> {
    return axios
      .get<CustomerDetailsResponse_2>(
        process.env.REACT_APP_SERVER_URL_2 + "/customer/" + customerId
      )
      .then((e) => {
        e.data.documents = [];
        return e;
      });
  }

  async updateCustomerDetails(
    customerId: number,
    customerDetails: DetailsFormValues
  ): Promise<AxiosResponse<CustomerDetailsResponse>> {
    const temp = formatUserDetails(customerDetails);
    return this.bookingApiRequestInstance.put<CustomerDetailsResponse>(
      process.env.REACT_APP_SERVER_URL_2 + "/customer/" + customerId,
      {
        ...temp,
        zip: parseInt(customerDetails.zip || ""),
        isDriver: customerDetails.isDriver?.toLowerCase() == "yes",
        customerType: customerDetails.customerType.toUpperCase(),
      }
    );
  }

  async getCity(cityName): Promise<AxiosResponse<StateResponse>> {
    return this.bookingApiRequestInstance.get<StateResponse>(
      process.env.REACT_APP_SERVER_URL_2 +
        "/locations/city/" +
        cityName +
        "/state_and_zip/all"
    );
  }
  async getStates(countryName = ""): Promise<AxiosResponse<string[]>> {
    let emptyCity;
    if (countryName != "") {
      return this.bookingApiRequestInstance.get<string[]>(
        process.env.REACT_APP_SERVER_URL_2 +
          "/locations/country/" +
          countryName +
          "/cities/all"
      );
    } else {
      const emptyCity: string[] = [];
      const emptyResponse: AxiosResponse<string[]> = {
        data: emptyCity,
        status: 200,
        statusText: "OK",
        headers: {},
        config: {},
      };
      return Promise.resolve(emptyResponse);
    }
  }
  async getReservationLocations(
    selectedAffiliateId = ""
  ): Promise<AxiosResponse<Location[]>> {
    return this.bookingApiRequestInstance.get<Location[]>(
      process.env.REACT_APP_SERVER_URL_2 +
        `/reservations/locations/all/?selectedAffiliate=${selectedAffiliateId}`
    );
  }

  async getAffiliate(): Promise<AxiosResponse<Affiliate[]>> {
    return this.bookingApiRequestInstance.get<Affiliate[]>(
      process.env.REACT_APP_SERVER_URL_2 + "/reservations/affiliate/all/"
    );
  }

  async getAllCountries(): Promise<AxiosResponse<string[]>> {
    return this.bookingApiRequestInstance.get<string[]>(
      process.env.REACT_APP_SERVER_URL_2 + "/locations/country/all"
    );
  }
  async getItalianCustomerDetailsBySSN(
    ssn: string
  ): Promise<AxiosResponse<ItalianCustomerDetailsUsingSSNResponse>> {
    return this.bookingApiRequestInstance.get<ItalianCustomerDetailsUsingSSNResponse>(
      process.env.REACT_APP_SERVER_URL_2 + "/customer/details/by/ssn/" + ssn
    );
  }
  async getSsnByCustomerDetails(
    firstName: string,
    lastName: string,
    birthNation: string,
    birthPlace: string,
    sex: string,
    dob: Date,
    country: string
  ): Promise<AxiosResponse<SsnByItalianCustomerDetails>> {
    const currentTime = new Date();
    const dobDatePart = new Date(dob);
    dobDatePart.setHours(currentTime.getHours());
    dobDatePart.setMinutes(currentTime.getMinutes());
    dobDatePart.setSeconds(currentTime.getSeconds());
    dobDatePart.setMilliseconds(currentTime.getMilliseconds());

    return this.bookingApiRequestInstance.post<SsnByItalianCustomerDetails>(
      process.env.REACT_APP_SERVER_URL_2 + "/customer/details_by_ssn",
      {
        firstName,
        lastName,
        birthNation: birthNation,
        birthPlace: birthPlace,
        sex: sex.toUpperCase(),
        dob: new Date(dobDatePart).toISOString(),
        national: country,
      }
    );
  }

  async getPrivacyPolicy(
    reservationId?: number | null,
    callFrom?: string
  ): Promise<AxiosResponse<PrivacyPolicy>> {
    return this.bookingApiRequestInstance.get<PrivacyPolicy>(
      process.env.REACT_APP_SERVER_URL_2 +
        "/reservations/privacy-policy/" +
        reservationId +
        "/" +
        callFrom
    );
  }
  async savePrivacyPolicy(
    reservationId: number,
    acceptedPolicies: number[]
  ): Promise<AxiosResponse<PrivacyPolicy>> {
    return this.bookingApiRequestInstance.post<PrivacyPolicy>(
      process.env.REACT_APP_SERVER_URL_2 +
        "/reservations/privacy-policy/" +
        reservationId,
      {
        acceptedPrivacyPolicies: acceptedPolicies,
      }
    );
  }
  async createBooking(
    firstName: string,
    lastName: string,
    email: string,
    phoneNo: string,
    reservationInfo: BookingDetails,
    customerInfo: DetailsFormValues,
    vehicleInfo: Vehicle,
    paymentInfo: SelectedVehicleDetails,
    paymentMethod: string,
    CompanyInfo: companyDetails,
    userId: string | undefined,
    onRequest: string | undefined,
    CustomerType: string | undefined,
    isInvoiceToCompanySelected: boolean | undefined,
    payWiretransfer: string | undefined,
    payVpos: string | undefined,
    selectedInsurance: string | undefined,
    extrasSelected: ChosenOptions["options"],
    extrasPrice: number
  ): Promise<AxiosResponse<ReservationSuccessfullResponse> | string> {
    const isValidSSN = await this.validateSSNField(
      customerInfo,
      firstName || customerInfo.firstName,
      lastName || customerInfo.lastName
    );
    let isValidCompanySSN = "true";
    let companySSn = "";
    if (
      isInvoiceToCompanySelected != undefined &&
      isInvoiceToCompanySelected == true &&
      CustomerType != undefined &&
      CustomerType != "" &&
      CustomerType == "Individual"
    ) {
      companySSn = document.getElementsByName("CompanySSNNumber")[0]?.value;
      isValidCompanySSN = await this.validateSSNFieldForCompany(
        CompanyInfo,
        companySSn
      );
    }
    if (isValidSSN == "true" && isValidCompanySSN == "true") {
      const currentTime = new Date();
      let companyDOB;
      if (CompanyInfo.birthDate) {
        companyDOB = new Date(CompanyInfo.birthDate.toString());
        companyDOB.setHours(currentTime.getHours());
        companyDOB.setMinutes(currentTime.getMinutes());
        companyDOB.setSeconds(currentTime.getSeconds());
        companyDOB.setMilliseconds(currentTime.getMilliseconds());
      }

      let customerDOB;
      if (customerInfo.dateOfBirth) {
        customerDOB = new Date(customerInfo.dateOfBirth.toString());
        customerDOB.setHours(currentTime.getHours());
        customerDOB.setMinutes(currentTime.getMinutes());
        customerDOB.setSeconds(currentTime.getSeconds());
        customerDOB.setMilliseconds(currentTime.getMilliseconds());
      }
      if (await this.validateVATNumber(CompanyInfo)) {
        return this.bookingApiRequestInstance.post<ReservationSuccessfullResponse>(
          process.env.REACT_APP_SERVER_URL_2 +
            "/reservations/touroperator/bookings",
          {
            isDiscounted: paymentInfo.isDiscounted,
            isPayLaterFee: paymentInfo.isPayLaterFee,
            payLaterFeeAmt: paymentInfo.payLaterFeeAmt,
            agreementCoupon: reservationInfo.agreementCoupon,
            discountValueWithoutVat: vehicleInfo?.Reference?.ID_without_vat
              ? vehicleInfo?.Reference?.ID_without_vat?.toString()
              : (
                  vehicleInfo.Reference.originalAmount -
                  vehicleInfo.Reference.ID
                ).toString(),
            isYoungDriverAge: reservationInfo.isYoungDriverAge,
            isYoungDriver2Age: reservationInfo.isYoungDriver2Age,
            youngDriver2Fee: vehicleInfo.youngDriver2Fee,
            youngDriverFee2Desc: vehicleInfo.youngDriverFee2Desc,
            youngDriverFee2Code: vehicleInfo.youngDriverFee2Code,
            isSeniorDriverAge: reservationInfo.isSeniorDriverAge,
            noFeeAge: reservationInfo.noFeeAge,
            noAgeFeeDesc: vehicleInfo.noAgeFeeDesc,
            noAgeFeeCode: vehicleInfo.noAgeFeeCode,
            seniorDriverFee: vehicleInfo.seniorDriverFee,
            seniorDriverFeeDesc: vehicleInfo.seniorDriverFeeDesc,
            seniorDriverFeeCode: vehicleInfo.seniorDriverFeeCode,
            youngDriverFee: vehicleInfo.youngDriverFee,
            youngDriverFeeDesc: vehicleInfo.youngDriverFeeDesc,
            youngDriverFeeCode: vehicleInfo.youngDriverFeeCode,
            onRequest: onRequest,
            reservationInfo: {
              endDate:
                DateFns.format(
                  reservationInfo.dropOffDateAndTime,
                  "yyyy-MM-dd"
                ) +
                "T" +
                DateFns.format(reservationInfo.dropOffDateAndTime, "HH:mm:ss"),
              startDate:
                DateFns.format(
                  reservationInfo.pickUpDateAndTime,
                  "yyyy-MM-dd"
                ) +
                "T" +
                DateFns.format(reservationInfo.pickUpDateAndTime, "HH:mm:ss"),
              pickUpLocation: reservationInfo.pickUpLocationDetails.code,
              dropOffLocation: reservationInfo.dropOffLocationDetails.code,
              bookingChannel: reservationInfo.reservationSource.code,
            },
            customerInfo: {
              firstName: firstName || customerInfo.firstName,
              lastName: lastName || customerInfo.lastName,
              email: email || customerInfo.email,
              telephone: phoneNo || customerInfo.telephone,
              ...formatUserDetails(customerInfo),
              isDriver: false,
              ...(CustomerType === "Private" && {
                dateOfBirth: new Date(customerDOB).toISOString(),
              }),
            },
            vehicleInfo: {
              VehicleCode: vehicleInfo.Vehicle?.Code,
            },
            paymentMethod: paymentMethod,
            insuranceId: selectedInsurance?.toString(),
            Fee: {
              Amount: !paymentInfo.extrasIncluded
                ? (paymentInfo.price + extrasPrice)?.toString()
                : paymentInfo.price.toString(),
              CurrencyCode: "EUR",
            },
            isEndUserBooking: "true", // to be enabled if payment method is not wiretransfer,
            onlineUser: { id: userId },
            bookingLang: i18n.language,
            payWiretransfer: payWiretransfer, // for MRent
            payVpos: payVpos, // for MRent
            CompanyInfo: {
              CompanyPhoneNumber: CompanyInfo?.CompanyPhoneNumber,
              CompanyEmail: CompanyInfo?.CompanyEmail,
              CompanyEInvoicingCode: CompanyInfo?.CompanyEInvoicingCode
                ? CompanyInfo?.CompanyEInvoicingCode
                : "",
              CompanyEInvoicingEmail: CompanyInfo?.CompanyEInvoicingEmail,
              CompanyBirthDate: CompanyInfo?.birthDate
                ? new Date(companyDOB).toISOString()
                : CompanyInfo.birthDate,
              CompanyBirthCity: CompanyInfo?.city,
              CompanyBirthProv: CompanyInfo?.CompanyBirthProv,
              CompanyBirthCountry: CompanyInfo?.country,
              CompanyStreet: CompanyInfo?.CompanyStreet,
              CompanyStreetNumber: CompanyInfo?.CompanyStreetNumber,
              CompanyCityName: CompanyInfo?.city,
              CompanyPostalCode: CompanyInfo?.CompanyPostalCode,
              CompanyStateProv: CompanyInfo?.CompanyBirthProv,
              CompanyCountry: CompanyInfo?.country,
              CompanyName: CompanyInfo?.CompanyName,
              CompanyVatNumber: CompanyInfo?.CompanyVatNumber,
              CustomerType: CustomerType,
              CompanyTaxCode: companySSn,
              IndividualFirstName: CompanyInfo?.firstName,
              IndividualLastName: CompanyInfo?.lastName,
              gender:
                CompanyInfo?.gender === "MASCHIO" ||
                CompanyInfo?.gender === "MALE"
                  ? true
                  : CompanyInfo?.gender === "FEMMINA" ||
                    CompanyInfo?.gender === "FEMALE"
                  ? false
                  : null,
            },
            optionals: Object.values(extrasSelected).map((e) => {
              return {
                Equiptype: e.name,
                Quantity: e.quantity.toString(),
                price: parseInt(e.price.toString()),
              };
            }),
          }
        );
      } else {
        return "invalid.company.vat";
      }
    } else if (isValidSSN == "Invalid TaxCode") {
      return "invalid.customer.ssn";
    } else {
      return "invalid.companyTaxCode";
    }
  }

  async getBookingFilterMethods(): Promise<AxiosResponse> {
    return this.bookingApiRequestInstance.get(
      process.env.REACT_APP_SERVER_URL_2 + "/reservations/getBookingFilterList"
    );
  }
  async getBookingAgeCriteria() {
    return this.bookingApiRequestInstance.get(
      process.env.REACT_APP_SERVER_URL_2 + `/reservations/getBookingAgeCriteria`
    );
  }

  getAllNewPaymentMethods() {
    return axios
      .get<NewPaymentsMethods>(
        process.env.REACT_APP_SERVER_URL_2 + "/settings/bookingPayments"
      )
      .then((e) => {
        return e.data;
      });
  }

  async validateSSNField(customerInfo: DetailsFormValues, firstName, lastName) {
    const currentTime = new Date();
    const dobDatePart = new Date(customerInfo.dateOfBirth);
    dobDatePart.setHours(currentTime.getHours());
    dobDatePart.setMinutes(currentTime.getMinutes());
    dobDatePart.setSeconds(currentTime.getSeconds());
    dobDatePart.setMilliseconds(currentTime.getMilliseconds());
    if (customerInfo.country?.toLowerCase().includes("ital")) {
      return await this.bookingApiRequestInstance
        .post<SsnByItalianCustomerDetails>(
          process.env.REACT_APP_SERVER_URL_2 + "/customer/details_by_ssn",
          {
            firstName: firstName,
            lastName: lastName,
            birthNation: customerInfo.birthNation,
            birthPlace: customerInfo.birthPlace,
            sex: customerInfo.sex ? customerInfo.sex.toUpperCase() : "",
            dob: customerInfo.dateOfBirth
              ? new Date(dobDatePart).toISOString()
              : "",
            national: customerInfo.country,
          }
        )
        .then((e) => {
          const data = e.data || "";
          if (
            data.toString().toLowerCase() !==
            customerInfo?.ssnNumber?.toLowerCase()
          ) {
            return "Invalid TaxCode";
          } else {
            return "true";
          }
        });
    } else {
      return "true";
    }
  }

  async validateSSNFieldForCompany(companyInfo: companyDetails, ssn) {
    const currentTime = new Date();
    const dobDatePart = new Date(companyInfo.birthDate);
    dobDatePart.setHours(currentTime.getHours());
    dobDatePart.setMinutes(currentTime.getMinutes());
    dobDatePart.setSeconds(currentTime.getSeconds());
    dobDatePart.setMilliseconds(currentTime.getMilliseconds());
    if (companyInfo.country?.toLowerCase().includes("ital")) {
      const gender =
        companyInfo.IndividualGender == "MASCHIO" ||
        companyInfo.IndividualGender == "MALE"
          ? "MALE"
          : companyInfo.IndividualGender == "FEMALE" ||
            companyInfo.IndividualGender == "FEMMINA"
          ? "FEMALE"
          : "";
      return await this.bookingApiRequestInstance
        .post<SsnByItalianCustomerDetails>(
          process.env.REACT_APP_SERVER_URL_2 + "/customer/details_by_ssn",
          {
            firstName: companyInfo.firstName,
            lastName: companyInfo.lastName,
            birthNation: companyInfo.birthNation,
            birthPlace: companyInfo.birthPlace,
            sex: companyInfo.gender ? companyInfo.gender.toUpperCase() : "",
            dob: companyInfo.birthDate
              ? new Date(dobDatePart).toISOString()
              : "",
            national: companyInfo.country,
          }
        )
        .then((e) => {
          const data = e.data || "";
          if (data.toString() !== ssn) {
            return "Invalid CompanyTaxCode";
          } else {
            return "true";
          }
        });
    } else {
      return "true";
    }
  }

  async validateVATNumber(CompanyInfo: companyDetails) {
    if (CompanyInfo.birthNation !== "ITALIA") {
      return true;
    } else {
      return await this.bookingApiRequestInstance
        .post<SsnByItalianCustomerDetails>(
          process.env.REACT_APP_SERVER_URL_2 + "/customer/validateVatApi",
          {
            vatNumber: CompanyInfo.CompanyVatNumber,
            country: CompanyInfo.country,
          }
        )
        .then((e) => {
          const data = e.data || "";
          if (data.toString() === "true") {
            return true;
          } else {
            return false;
          }
        });
    }
  }

  getPaymentLink(reservationId: number, amount: number, paymentMode: string) {
    return axios
      .post<PaymentLinkResponse>(
        process.env.REACT_APP_SERVER_URL_2 +
          `/settings/paymentLink/${reservationId}/${amount}`,
        {
          paymentMode,
        }
      )
      .then((e) => {
        return e.data;
      });
  }
  getAppSetting() {
    return axios
      .get<AppSettingResponse>(
        process.env.REACT_APP_SERVER_URL_2 + `/settings/app`
      )
      .then((e) => {
        return e.data;
      });
  }
  createNewUser(e) {
    return axios
      .post(
        process.env.REACT_APP_SERVER_URL_2 + "/customer/registerBookingUser",
        {
          firstName: e.firstName,
          lastName: e.lastName,
          phoneNumber: e.phoneNumber,
          email: e.email.toLowerCase(),
          password: e.password,
          birthDate: e.birthDate,
          onlineUserType: 3,
          onlineUserRole: 2,
          onlineUserStatus: 1,
        }
      )
      .then(function (response) {
        //  console.log(response);
        return response;
      });
  }

  loginUser(e) {
    return axios
      .post(process.env.REACT_APP_SERVER_URL_2 + "/customer/login", {
        email: e.email.toLowerCase(),
        password: e.password,
      })
      .then(function (response) {
        //   console.log(response);
        localStorage.setItem("userAffiliate", response?.data?.user?.affiliate);
        return response;
      });
  }

  getBookingDetailsbyUserId(userid: number, offset: number, max: number) {
    return this.bookingApiRequestInstance.get(
      process.env.REACT_APP_SERVER_URL_2 +
        `/customer/bookinglist/by/userid/${userid}/reservation/${offset}/${max}`
    );
  }

  getRentalDetailsbyUserId(userId: number) {
    return this.bookingApiRequestInstance.get(
      process.env.REACT_APP_SERVER_URL_2 +
        `/customer/getRentalsForOnlineUser/${userId}`
    );
  }

  getLastBookingDetail(userId: number) {
    return this.bookingApiRequestInstance.get(
      process.env.REACT_APP_SERVER_URL_2 +
        `/customer/getLastBookingDetails/${userId}`
    );
  }

  getOnlineUserInvoices(userId: number, offset: number, max: number) {
    return this.bookingApiRequestInstance.get(
      process.env.REACT_APP_SERVER_URL_2 +
        `/customer/getInvoiceListForOnlineUser/${userId}/${offset}/${max}`
    );
  }

  downloadInvoice(invoiceId: number) {
    return this.bookingApiRequestInstance.get(
      process.env.REACT_APP_SERVER_URL_2 +
        `/customer/downloadInvoice/${invoiceId}`
    );
  }

  UpdateUserProfileById(id, e) {
    return axios.post(
      process.env.REACT_APP_SERVER_URL_2 +
        `/customer/onlineUser/updateOnlineUser/${id}`,
      {
        firstName: e.firstName,
        lastName: e.lastName,
        phoneNumber: e.phoneNumber,
        userImage: e.userImage,
      }
    );
  }

  getUserProfileByUserId(userid: number) {
    return this.bookingApiRequestInstance.get(
      process.env.REACT_APP_SERVER_URL_2 + `/customer/getUserProfile/${userid}`
    );
  }

  async getCustomerDeatilsByUserid(
    userid: number,
    offset: number,
    max: number
  ) {
    return await this.bookingApiRequestInstance.get(
      process.env.REACT_APP_SERVER_URL_2 +
        `/customer/onlineUser/${userid}/customer/${offset}/${max}`
    );
  }
  async updateCustomerDetailByCustomerId(id, cid, data) {
    return await axios
      .put(process.env.REACT_APP_SERVER_URL_2 + `/customer/${id}/${cid}`, {
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        companyName: data.companyName,
        mobileNumber: data.mobileNumber,
        email: data.email,
        country: data.country,
        state: data.state,
        city: data.city,
        streetName: data.street,
        streetNumber: data.streetNumber,
        zip: data.zip,
        vatNumber: data.vatNumber,
        ssnNumber: data.ssnNumber,
        sex: data.sex,
        telephone: data.telephone,
        dateOfBirth: data.dateofBirth,
        birthPlace: data.birthPlace,
        birthProvince: data.birthProvidence,
        birthNation: data.birthNation,
        isDriver: data.isDriver,
        license: data.licence,
        issuedBy: data.issuedBy,
        releaseDate: data.releaseDate,
        licenseType: data.licenceType,
        documentIssueDate: data.documentIssueDate,
        documentExpiryDate: data.documentExpiryDate,
        documentNumber: data.documentNumber,
        document: data.document,
        customerId: data.customerId,
        customerType: data.customerType,
      })
      .then((e) => {
        return e;
      });
  }

  async sentVerificationcode(e) {
    return await axios
      .post(
        process.env.REACT_APP_SERVER_URL_2 + `/customer/login/sendVerification`,
        {
          email: e,
        }
      )
      .then((e) => {
        return e.data;
      })
      .catch((err) => {
        return err;
      });
  }
  async forgotPassword(otp) {
    return await axios
      .post(
        process.env.REACT_APP_SERVER_URL_2 +
          `/customer/login/verifyOtpChangePassword`,
        {
          verificationCode: otp.verificationCode,
          emailId: otp.email,
          newPassword: otp.newPassword,
        }
      )
      .then((e) => {
        return e.data;
      })
      .catch((err) => {
        return err;
      });
  }

  async changePassword(data) {
    const id: any = localStorage.getItem("userID");
    return await axios
      .post(
        process.env.REACT_APP_SERVER_URL_2 +
          `/customer/changePasswordForOnlineUser/${id}`,
        {
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
          confirmPassword: data.confirmPassword,
        }
      )
      .then((e) => {
        return e.data;
      })
      .catch((err) => {
        return err;
      });
  }

  getInsuranceDetails(
    reservationDetails: BookingDetails,
    vehicleGroup: string,
    allVehicleAllOptions: OptionInVehicle[]
  ) {
    return axios
      .post<InsuranceDetails>(
        process.env.REACT_APP_SERVER_URL_2 +
          "/reservations/touroperator/getInsuranceList",
        {
          startDate: reservationDetails.pickUpDateAndTime,
          endDate: reservationDetails.dropOffDateAndTime,
          reservationSource:
            reservationDetails.reservationSource.code || "WEB001",
          group: vehicleGroup,
          locationCode: reservationDetails.pickUpLocationDetails.code,
          extras: allVehicleAllOptions?.map((e) => {
            return {
              code: e.Equipment.EquipType,
              amount: e.Charge.Amount,
            };
          }),
        }
      )
      .then((e) => {
        e.data["Booking Insurance"] = e.data["Booking Insurance"].sort(
          (o1, o2) => {
            return o1.Amount - o2.Amount;
          }
        );
        return e.data;
      });
  }
  sendEmailForWireTransfer(id: number, onRequest: string, language: string) {
    return axios.post(
      process.env.REACT_APP_SERVER_URL_2 +
        `/reservations/touroperator/${id}/sendEmail`,
      {
        onRequest: onRequest,
        language: language,
      }
    );
  }

  savePaymentForNexi(paymentDetails: NexiChargeTransactionDetails) {
    return axios.post(
      process.env.REACT_APP_SERVER_URL_2 + `/reservations/handlePayments`,
      {
        ...paymentDetails,
      }
    );
  }

  savePaymentForStripe(paymentDetails: StripeTransactionDetails) {
    return axios.post(
      process.env.REACT_APP_SERVER_URL_2 + `/reservations/handlePayments1`,
      {
        ...paymentDetails,
      }
    );
  }

  getPrivacyAndTerms() {
    return axios
      .get<PrivacyTerms>(
        process.env.REACT_APP_SERVER_URL_2 +
          "/reservations/touroperator/getPrivacyAndTerms/" +
          i18n.language
      )
      .then((e) => {
        return e.data;
      });
  }

  getResPrint(id: string) {
    return axios
      .get<AxiosResponse>(
        process.env.REACT_APP_SERVER_URL_2 +
          "/reservations/touroperator/getResPrint/" +
          id
      )
      .then((e) => {
        return e.data;
      });
  }
  async getTextResource(lang: string) {
    const response = await axios.get<AxiosResponse>(
      `${process.env.REACT_APP_SERVER_URL_2}/settings/getTextResource/${lang}`
    );
    return response;
  }
  cancelBooking(
    prefix: string,
    number: string,
    codice: string,
    cancelReason: string,
    reservationSourceCode: string
  ) {
    return axios.post(
      process.env.REACT_APP_SERVER_URL_2 + "/reservations/cancelBooking",
      {
        prefix: prefix.toString(),
        number: number.toString(),
        codice: codice.toString(),
        cancelReason: cancelReason.toString(),
        channel: reservationSourceCode.toString(),
      }
    );
  }

  couponDiscount(
    couponCode: string,
    startDate: string,
    endDate: string,
    rateAmount: string,
    optionalAmount: string,
    totalAmount: string,
    refIdContext: string
  ) {
    return axios.post(
      process.env.REACT_APP_SERVER_URL_2 + "/reservations/couponDiscount",
      {
        couponCode: couponCode.toString(),
        startDate: startDate.toString(),
        endDate: endDate.toString(),
        rateAmount: rateAmount.toString(),
        optionalAmount: optionalAmount.toString(),
        totalAmount: totalAmount.toString(),
        refIdContext: refIdContext.toString(),
      }
    );
  }

  getReservationSourceForOnlineUser(userId: number) {
    return this.bookingApiRequestInstance.get(
      process.env.REACT_APP_SERVER_URL_2 +
        `/customer/getReservationSourceForOnlineUser/${userId}`
    );
  }

  fetchTheme() {
    return this.bookingApiRequestInstance.get(
      process.env.REACT_APP_SERVER_URL_2 +
        `/reservations/fetchDynamicThemePalette`
    );
  }
}
function formatUserDetails(
  customerInfo: DetailsFormValues
): Record<string, any> {
  const tempCustomerInfo: Record<string, any> = { ...customerInfo };
  for (const i in tempCustomerInfo) {
    if (tempCustomerInfo[i] == "") {
      delete tempCustomerInfo[i];
    } else if (i == "zip" && tempCustomerInfo[i]) {
      tempCustomerInfo[i] = parseInt((tempCustomerInfo[i] || "").toString());
    } else if (i == "ssnNumber" && tempCustomerInfo[i]) {
      tempCustomerInfo[i] = (tempCustomerInfo[i] || "").toString();
    }
    if (i == "customerDocs") {
      delete tempCustomerInfo[i];
    }
    // console.log(tempCustomerInfo);
  }
  return tempCustomerInfo;
}

const BookingApiInstance: BookingApi = new BookingApi();
export { BookingApiInstance as default, BookingApiInstance as BookingApi };
