import React, { useEffect, useState } from "react";
import DoneTwoTone from "@mui/icons-material/DoneTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DeleteOutline } from "@material-ui/icons";

const InsurancePreview = ({
  insurance,
  specification,
  updateShowInsurance,
  previewModal,
}) => {
  const [addedSpecification, setAddedSpecification] = useState([]);
  const { t: translate } = useTranslation();
  useEffect(() => {
    if (previewModal === "selectedInsurance") {
      const newSpecifications = specification.reduce((acc, e) => {
        if (e.Description === insurance.details) {
          const bookingTextEntries = Object.entries(e.bookingText)
            .filter(([key]) => key.includes(e.Description)) // Filter entries containing the description
            .map(([key, value]) => {
              const cleanedKey = key.replace(`:${e.Description}`, ""); // Remove the description from the key
              return `${cleanedKey} : ${value}`;
            });
          return [...acc, ...bookingTextEntries];
        }
        return acc;
      }, []);
      setAddedSpecification(newSpecifications);
    } else {
      const newSpecifications = specification.reduce((acc, e) => {
        if (e.Description === insurance.Description) {
          const bookingTextEntries = Object.entries(e.bookingText)
            .filter(([key]) => key.includes(e.Description)) // Filter entries containing the description
            .map(([key, value]) => {
              const cleanedKey = key.replace(`:${e.Description}`, ""); // Remove the description from the key
              return `${cleanedKey} : ${value}`;
            });
          return [...acc, ...bookingTextEntries];
        }
        return acc;
      }, []);
      setAddedSpecification(newSpecifications);
    }
  }, [specification, insurance]);

  return (
    <div className="relative flex flex-wrap text-black animate__animated animate__zoomInRight p-8 shadow-lg rounded-lg bg-white">
      {previewModal === "selectedInsurance" && (
        <div className="absolute top-2 right-2">
          <IconButton>
            <DeleteOutline
              onClick={updateShowInsurance}
              className="text-red-500"
            />
          </IconButton>
        </div>
      )}
      {previewModal !== "selectedInsurance" && (
        <div style={{ width: "-webkit-fill-available" }}>
          <h3 className="text-xl mb-2">{translate("your.plan.includes")} </h3>
        </div>
      )}
      {previewModal === "selectedInsurance" ? (
        <div style={{ width: "-webkit-fill-available" }}>
          <h1 className="text-xl font-bold mb-2">{insurance.details}</h1>
        </div>
      ) : (
        <div style={{ width: "-webkit-fill-available" }}>
          <h1 className="text-xl font-bold mb-2">{insurance.Description}</h1>
        </div>
      )}
      <div>
        <ul className="list-disc pl-5">
          {addedSpecification.map((spec, index) => (
            <li key={index} className="flex items-center">
              <DoneTwoTone className="text-green-800 font-bold mr-2" />
              {spec}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default InsurancePreview;
