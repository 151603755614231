import { t } from "i18next";
import { AppRadioButton } from "../../components/UI/AppRadioButtons/AppRadioButtons";
import {
  CompanyInvoiceFormFields,
  ReadOnlyIfInitialValuesNotProvided,
} from "../../components/Layouts/WebCheckInForms/FormOptions";
import { RegisterOptions } from "react-hook-form";
import { AppInputSwitcher } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher";
import { FormFieldErrorMessage } from "../../components/UI/FormFieldErrorMessage/FormFieldErrorMessage";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWebCheckInForm } from "../../hooks/WebCheckInForm_2";
import {
  DefaultValuesDetailsForm,
  IndividualFormProps,
} from "../../components/Layouts/WebCheckInForms/WebCheckInForms.props";
import PrivatePersonalDetails from "./PrivatePersonalDetails";
import { TextResource } from "../../@types/DTO/AppSetting";
import BookingGetters from "../../store/onlineBooking/getters";

function NewCompanyDetails(
  {
    userType,
    handleUserType,
    setCompanyinvoiceDetails,
    companyinvoiceDetails,
    phoneError,
    phoneRef,
    dateError,
    isAirport,
  },
  {
    onSubmit = () => {},
    values = {},
    initialValues = { ...DefaultValuesDetailsForm },
    onChange = () => {},
    children = <></>,
  }: IndividualFormProps
) {
  const [
    {
      setValue,
      getValues,
      register,
      handleSubmit,
      formState: { errors },
    },
    individualFormFields,
  ] = useWebCheckInForm(
    CompanyInvoiceFormFields,
    {
      ...Object.keys(values).reduce((prevValue, currentKey) => {
        prevValue[currentKey] =
          prevValue[currentKey] || initialValues[currentKey];
        return prevValue;
      }, values),
    },
    onChange
  );
  const { t: translate } = useTranslation();
  const [taxVal, setTaxVal] = useState("9999999999999999");
  const [isReadonly, setIsReadonly] = useState(true);
  const istabletScreen = window.innerWidth > 768 && window.innerWidth < 1300;
  const handleUserChange = (event) => {
    handleUserType(event);
  };
  // State for PrivatePersonalDetails
  const [privatePersonalDetails, setPrivatePersonalDetails] = useState({
    ...DefaultValuesDetailsForm,
  });
  const textResource = BookingGetters.getTextResource()?.data ?? [];
  const isSmallScreen = window.innerWidth <= 768;
  const [requiredFieldLabel, setRequiredFieldLabel] = useState<String>("");
  const [companyDetailsLabel, setCompanyDetailsLabel] = useState<String>("");
  useEffect(() => {
    const data: TextResource[] = textResource ?? [];
    const requiredFieldData = data.find(
      (item) => item.key === "RequiredFieldLabel"
    );
    setRequiredFieldLabel(
      requiredFieldData?.body || translate("This is a required field")
    );
    const companyDetailsLabelData = data.find(
      (item) => item.key === "companyDetailsLabel"
    );
    setCompanyDetailsLabel(
      companyDetailsLabelData?.body || translate("company_details")
    );
  }, [textResource]);
  const handleCountryChange = (newCountryValue) => {
    setValue("country", newCountryValue || "");
    setValue("city", "");
    setValue("state", "");
  };
  const AppSettings = BookingGetters.getAppSettings();
  return (
    <div
      className={`bg-white box-border ${
        isSmallScreen ? "p-2" : "p-8"
      } justify-center`}
    >
      <h2
        className="text-xl text-black pb-8 font-bold"
        style={{
          paddingLeft: isSmallScreen ? ".7rem" : "",
          paddingTop: isSmallScreen ? "2rem" : "",
        }}
      >
        {companyDetailsLabel}
      </h2>
      <div className={`flex flex-col ${isSmallScreen ? "px-2" : ""}`}>
        <>
          <AppRadioButton
            required={true}
            value={userType}
            onChange={(e) => {
              handleUserChange(e);
            }}
            options={[
              {
                label: t("Private"),
                value: "Private",
              },
              {
                label: t("Company"),
                value: "Company",
              },
              {
                label: t("Individual"),
                value: "Individual",
              },
            ]}
            color={"primary"}
          />
          <div
            onSubmit={handleSubmit((e) => {
              onSubmit(e);
            })}
          >
            {userType === "Private" ? (
              <PrivatePersonalDetails
                userDetails={privatePersonalDetails}
                setUserDetails={setPrivatePersonalDetails}
                dateError={dateError}
                isAirport={isAirport}
              />
            ) : (
              <div
                className={`grid sm:grid-cols-2 ${
                  istabletScreen ? "md:grid-cols-2" : "md:grid-cols-3"
                } gap-4`}
                style={{
                  padding: isSmallScreen ? "0px" : "2rem",
                }}
              >
                {Object.values(individualFormFields).map((el, index) => {
                  let taxValue = "";
                  let readOnly;
                  const telephoneValue =
                    el.key === "telephone"
                      ? getValues(el.key) ||
                        initialValues[el.key] ||
                        values[el.key] ||
                        ""
                      : "";
                  el.readOnly ||
                    (ReadOnlyIfInitialValuesNotProvided.includes(el.key) &&
                      !!initialValues[el.key]);
                  const options: RegisterOptions = {
                    required: {
                      value: !!el.required && !readOnly,
                      message: requiredFieldLabel,
                    },
                  };

                  if (el.type === "email") {
                    options.pattern = {
                      value: /^[a-zA-Z\d+_.-]+@[a-zA-Z\d.-]+$/,
                      message: "Please provide a valid email",
                    };
                  }
                  if (el.key === "taxCode") {
                    taxValue = taxVal;
                    readOnly = isReadonly;
                  } else {
                    taxValue = "";
                  }
                  return (
                    <>
                      {userType === "Company" &&
                      (el.key === "birthNation" ||
                        el.key === "birthPlace" ||
                        el.key === "dateOfBirth" ||
                        el.key === "birthProvince" ||
                        el.key === "taxCode" ||
                        el.key === "firstName" ||
                        el.key === "lastName" ||
                        el.key === "sex") ? (
                        ""
                      ) : (
                        <div
                          key={index}
                          style={{
                            paddingBottom:
                              isSmallScreen && el.key === "sex"
                                ? "20px"
                                : "0px",
                          }}
                        >
                          <AppInputSwitcher
                            {...el}
                            key={index}
                            autocomplete={false}
                            inputRef={
                              el.key === "telephone" &&
                              phoneRef &&
                              AppSettings?.phoneNoRequired
                            }
                            required={
                              (el.key === "firstName" &&
                                AppSettings?.firstNameRequired) ||
                              (el.key === "lastName" &&
                                AppSettings?.lastNameRequired) ||
                              (el.key === "companyName" &&
                                AppSettings?.companyNameRequired) ||
                              (el.key === "telephone" &&
                                telephoneValue.length < 5 &&
                                AppSettings?.phoneNoRequired) ||
                              (el.key === "email" &&
                                AppSettings?.emailRequired) ||
                              (el.key === "country" &&
                                AppSettings?.countryRequired) ||
                              (el.key === "city" &&
                                AppSettings?.cityRequired) ||
                              (el.key === "dateOfBirth" &&
                                AppSettings?.birthDateRequired) ||
                              (el.key === "birthNation" &&
                                AppSettings?.birthNationRequired) ||
                              (el.key === "birthPlace" &&
                                AppSettings?.birthPlaceRequired) ||
                              (el.key === "streetName" &&
                                AppSettings?.streetNameRequired) ||
                              (el.key === "street number" &&
                                AppSettings?.nRequired) ||
                              (el.key === "birthProvince" &&
                                AppSettings?.birthProvRequired) ||
                              (el.key === "CompanyEInvoicingEmail" &&
                                AppSettings?.sdiEmailRequired) ||
                              (el.key === "CompanyEInvoicingCode" &&
                                AppSettings?.sdiCodeRequired) ||
                              (el.key === "zip" &&
                                AppSettings?.zipCodeRequired) ||
                              (el.key === "vatNumber" &&
                                AppSettings?.vatNumberRequired) ||
                              (el.key === "sex" &&
                                AppSettings?.genderRequired) ||
                              (el.key === "CompanySSNNumber" &&
                                AppSettings?.ssnRequired)
                            }
                            {...register(el.key, {
                              ...options,
                            })}
                            readOnly={readOnly}
                            value={
                              getValues(el.key) ||
                              initialValues[el.key] ||
                              values[el.key] ||
                              taxValue ||
                              ""
                            }
                            options={
                              el.key === "sex"
                                ? [translate("MALE"), translate("FEMALE")]
                                : el.options
                            }
                            label={translate(el.label || "")}
                            placeholder={translate(el.placeholder || "")}
                            className={`h-20 box-border ${
                              !isSmallScreen ? "px-8" : ""
                            }`}
                            onchange={(e) => {
                              const r = Object.keys(
                                CompanyInvoiceFormFields
                              ).at(index);
                              if (el.key === "birthNation" && e === "ITALIA") {
                                setTaxVal("");
                                setIsReadonly(false);
                              } else if (
                                el.key === "birthNation" &&
                                e !== "ITALIA"
                              ) {
                                setTaxVal("9999999999999999");
                                setIsReadonly(true);
                              }
                              setCompanyinvoiceDetails({
                                ...companyinvoiceDetails,
                                [r]: e,
                              });
                              if (el.key == "country") {
                                handleCountryChange(e);
                              }
                              setValue(el.key, e || "", {
                                // shouldValidate: true,
                              });
                            }}
                          />
                          <FormFieldErrorMessage>
                            {errors[el.key]?.message}
                          </FormFieldErrorMessage>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            )}
            {children}
          </div>
        </>
      </div>
    </div>
  );
}
export default NewCompanyDetails;
